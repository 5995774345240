

































import { Component, Vue } from "vue-property-decorator";

import SearchBar from "@/components/SearchBar.vue";

// Components
// import Navigation from "@/components/Navigation.vue"; // @ is an alias to /src
// import Footer from "@/components/Footer.vue"; // @ is an alias to /src
@Component({
  components: {
    SearchBar
  }
})
export default class Header extends Vue {
  

}



