





























































import { Component, Vue } from "vue-property-decorator";

import router from "@/router";

import axios from 'axios';

// Components
// import Navigation from "@/components/Navigation.vue"; // @ is an alias to /src
// import Footer from "@/components/Footer.vue"; // @ is an alias to /src
@Component({
  components: {
  }
})
export default class SearchBar extends Vue {
  
  public query: string = '';
  public search_results: any = [
	  {
		  type: "Function",
		  shortcode: "HOME",
		  name: "Homepage",
      p_type: {
        type: "Function"
      }
	  },
	  {
		  type: "Equity",
		  ticker: "APT",
		  name: "Afterpay",
      p_type: {
        type: "Equity"
      }
	  },
	  
	  {
		  type: "Equity",
		  ticker: "CBA",
		  name: "Commonwealth Bank",
      p_type: {
        type: "Equity"
      }
	  },
	  {
		  type: "Equity",
		  ticker: "WHC",
		  name: "Whitehaven Coal",
      p_type: {
        type: "Equity"
      }
	  }
  ];
  
  public show_results: boolean = false;
  
  public search_base: any[] = [];
  
  
  async searchBarClick() {
	  
	for(let item of this.search_results) item.selected = false;
	  
		this.show_results = true;
		
		await this.$nextTick()
		
		let search_field: any = this.$refs.search
		search_field.select();
  }
  
  overlayClick() {
  	this.show_results = false;
  }
  
  async loadStocks() {
	  let { data } = await axios.get('https://instruments.b8s.workers.dev');
	  

	  
	  let generics: any = [
	  {
		  type: "Function",
		  symbol: "HOME",
		  name: "Homepage"
	  }]
	  
	  this.search_base = [...generics, ...data];
  }
  
  loadSecurity(item: any) {
	  
	  let search_field: any = this.$refs.search
	  
	  this.show_results = false;
	  this.query = item.symbol;
	
	  search_field.blur();
	  
	  let security_type = item.type;
	  
	  if(item.type == "au_equity") {
      console.log("LOAD AU SHARE", {params: { symbol: item.symbol } });
    router.push({ name: 'AU Share', params: { symbol: item.symbol } });
    }
    else if(item.type == "us_equity") {
      console.log("US SHARE", {params: { symbol: item.symbol } });
	  	router.push({ name: 'US Share', params: { symbol: item.symbol } });
    }
	  else if(item.type == "crypto") {
      console.log("CRYPTO SHARE", {params: { symbol: item.symbol } });
	  	router.push({ name: 'Crypto', params: { symbol: item.symbol } });
    }
	  
		 
	  
	  window.scrollTo(0,0);

  }
  
  searchInputPress($event: KeyboardEvent) {
	 
	  
	  let tickerKey = new RegExp(this.query.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), "i"); 
	  let results = [];
	  
	  for(let candidate of this.search_base) {
		
		let points = 0;
		
		//Exact match, 40 points
		if(candidate.symbol.toLowerCase() == this.query) points += 40;
		
		//Match in ticker 10 points + 3 for every letter
		if(tickerKey.test(candidate.symbol)) points += 5 + (this.query.length * 3);
		
		//Match in name, 1 + 3 points for every letter
		if(tickerKey.test(candidate.display_name)) points += 1 + (this.query.length * 3);
		
		if(points > 8) {
			results.push(Object.assign({points}, candidate));
		} 
		
	  }
	  
	  results.sort(function(a, b){return b.points - a.points});
	  
	  //If its the only item, select it.
	  if(results.length == 1) results[0].selected = true;
	  else if (results.length > 1 && results[0].symbol.toLowerCase() == this.query) results[0].selected = true;
	  //If there is more than 1 result, the one at the top is an exact match select it.
	  
    let selected_results = results.slice(0, 5);

    let types: any = {
      "au_equity" : {
        type: "AU Equity",
        exchange: "au"
      },
      "us_equity" : {
        type: "US Equity",
        exchange: "us"
      },
      "crypto": {
        type: "crypto",
        exchange: "crypto"
      }
    }

    for(let selected_result of selected_results) {
    
      selected_result.p_type = types[selected_result.type];
    }
	  
	  this.search_results = results.slice(0, 5);
	  
	  
  }
  
  searchKeyDown($event: KeyboardEvent) {
	
	  
    let { key } = $event;
	
	//Get currently selected index
	let selected_index = this.search_results.findIndex((item: any) => item.selected);
	
	let max_index = this.search_results.length;
	
	switch (key) {
		case("ArrowDown"):
			selected_index++;
			if(selected_index >= max_index) selected_index = 0;
			$event.stopPropagation();
			$event.preventDefault();
			break;
		case("ArrowUp"):
			selected_index--;
			if(selected_index < 0) selected_index = max_index - 1;
			$event.stopPropagation();
			$event.preventDefault();
			break;

			
		case("Enter"):
			let selected_item = this.search_results.find((item: any) => item.selected);
			
			//1 Result
			if(!selected_item && max_index == 1) selected_item = this.search_results[0];
			selected_index = -1;
			this.loadSecurity(selected_item);
			break;
		case("Escape"):
			this.show_results = false;
			break;
	}
	
	if(selected_index >= 0) {
		for(let item of this.search_results) item.selected = false;
		this.search_results[selected_index].selected = true;
		this.$forceUpdate();
	}else{
		for(let item of this.search_results) item.selected = false;
	}
	
	
	
	
  }
  
  mounted() {
	  this.loadStocks();
	  
	window.addEventListener("keydown", e => {
        
	  if(e.keyCode == 191) {
		  
		e.preventDefault();
		this.searchBarClick();

	  }
	  else if(e.keyCode == 38 && window.scrollY == 0) {
		  e.preventDefault();
		  this.searchBarClick();
	  }
		});
  }

}



